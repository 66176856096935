'use client';
import { usePush } from '@/common/usePush';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { PFText } from './PFText';
import { PFButton } from './PFButton';
import Image from 'next/image';
import { useEffect, useState } from 'react';

const hasSeenPushBannerAtom = atomWithStorage('hasSeenPushBanner', false);

export function PushNotificationBanner() {
  const [hasSeenPushBanner, setHasSeenPushBanner] = useAtom(
    hasSeenPushBannerAtom,
  );

  const { isPushAviable, askPermission, subscribeButtonOnClick, isSubscribed } =
    usePush(undefined, true);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (!isPushAviable() || hasSeenPushBanner || isLoading || isSubscribed) {
    return;
  }

  return (
    <div className="shadow-lg rounded-b-xl w-full sm:max-w-[500px] fixed bottom-0 sm:bottom-auto sm:top-0 left-[50%] translate-x-[-50%] bg-white dark:bg-secundaryColor z-[100000] p-base">
      <div className="flex gap-base">
        <div className="min-w-[60px] h-[60px]">
          <Image
            src={'/static/logo_1024.png'}
            alt="PF Logo"
            width={60}
            height={60}
          />
        </div>
        <PFText size="small">
          Verpasse keine Promiflash-Topnews! Melde dich jetzt für
          Push-Benachrichtigungen an und bleib immer auf dem Laufenden.
        </PFText>
      </div>
      <div className="pt-base" />
      <div className="flex gap-base">
        <PFButton
          variant="textOnly"
          onClick={() => {
            setHasSeenPushBanner(true);
          }}
        >
          <PFText size="small">Schließen</PFText>
        </PFButton>
        <PFButton
          variant="base"
          onClick={(event) => {
            askPermission()
              .then(() => {
                subscribeButtonOnClick(event);
              })
              .catch((err) => {
                console.error(err);
              });
            setHasSeenPushBanner(true);
          }}
        >
          <PFText size="small">Push aktivieren</PFText>
        </PFButton>
      </div>
    </div>
  );
}

'use client';
import { useEffect, useState } from 'react';

export default function OfflineBanner() {
  const [offline, setOffline] = useState(false); //typeof navigator !== 'undefined' && navigator.onLine === false

  useEffect(() => {
    const updateOnlineStatus = () => {
      setOffline(navigator.onLine === false);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <>
      {offline && (
        <div className="offline flex w-full h-[50px] justify-center">
          <p className="z-[999] fixed bg-secundaryColor w-full text-white text-center p-3">
            Keine Internet Verbindung gefunden.
          </p>
        </div>
      )}
    </>
  );
}

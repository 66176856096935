'use client';
import { usePush } from '@/common/usePush';
import { Suspense, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const NotificationBellButton = dynamic(
  () =>
    import('./NotoficationBellButton').then(
      (component) => component.NotificationBellButton,
    ),
  { ssr: false, loading: () => <div className="w-9" /> },
);

export function NotoficationBellButtonClientWrapper() {
  return <NotificationBellButton />;
}
